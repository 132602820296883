import React from "react"
import { Link, navigate } from "gatsby"
import styled from 'styled-components';
import axios from 'axios'
import Layout from "../layouts"
import Seo from "../components/seo"
import {BreadCrumb, Section, FlexWrap, RightWrap, LeftWrap, SectionPageTitle, SectionDescription, SubTitle,   Checkbox,	HelpText} from "../components/Section"
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton";
import LineArrowRight from "../components/Icons/LineArrowRight"
import MapPointerIcon from "../components/Icons/MapPointerIcon"
import PhoneIcon from "../components/Icons/PhoneIcon"
import MailIcon from "../components/Icons/MailIcon"

const MapWrap = styled.div`
	position: relative;
	z-index: 2;
	background: white;
	margin-bottom: -180px;
	padding-bottom: 80px;

	iframe {
		margin-top: -180px;
		border-radius: 6px;
		box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
	}
	@media(max-width: 991px) {
		iframe {
			margin-top: 50px;
		}
	}
`

const FormWrap = styled.form`
	padding: 42px 50px 50px;
	background: white;
	border-radius: 6px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, .1);

	h3 {
		font-size: 26px;
		line-height: 24px;
		margin-bottom: 19px;
		font-family: Eina03-Bold;
	}
	input {
		width: 100%;
		margin-bottom: 20px;
		padding: 16px 20px 18px;
		border: 1px solid #D0D0D0;
		border-radius: 6px;
		box-shadow: 0 5px 12px rgba(0, 0, 0, .1);
	}
	textarea {
		width: 100%;
		margin-bottom: 20px;
		padding: 16px 20px 18px;
		border: 1px solid #D0D0D0;
		border-radius: 6px;
		box-shadow: 0 5px 12px rgba(0, 0, 0, .1);
		resize: none;
	}
`

const AddressWrap = styled.div`
	display: flex;
	p {
		margin-top: 0;
		padding-top: 0;
		margin-left: 10px;
		color: #574C4C;
		margin-bottom: 15px;

		a {
			color: #574C4C;

			:hover {
				text-decoration: underline;
			}
		}
	}
	svg {
		margin-top: 5px;
	}
`
class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      first_name: '',
      last_name: '',
      city: '',
      email: '',
      phone: '',
      zipcode: '',
      comment: '',
	  agreeViaSms: true,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange(event) {
    const {id, value} = event.target
    if (id !== "agreeViaSms") {
      this.setState({
        [id]: value,
      })
    } else {
      this.setState({
        [id]: event.target.checked,
      })
    }
  }
  handleSubmit(event) {
    event.preventDefault()
	const { location } = this.props;
    const data = {
		source: location.origin,
		form: "Quote Form in the Contact Page",
		page: location.pathname,
		first_name: this.state.first_name,
		last_name: this.state.last_name,
		email: this.state.email,
		phone_no: this.state.phone,
		city: this.state.city,
		zipcode: this.state.zipcode,
		comment: this.state.comment + ", SMS consent : " + this.state.agreeViaSms,
        agreeViaSms: this.state.agreeViaSms,

    }
	//console.log("==========", data)
    axios({
      method: "post",
       url: "https://crm.senseicrm.com/api/create-lead-wordpress",
       data: data,
       headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN }
     }).then((res) => {
       this.setState({ first_name: '', last_name: '', email: '', phone: '', zipcode: '', comment: '', agreeViaSms: false});
       navigate("/thank-you-contact-us");
     }).catch((error) => {
         alert(error)
     });
  }
  render() {
	const organizationSchema = {
		"@context": "https://schema.org", 
		"@type": "Organization", 
		"name": "Buildings And More", 
		"url": "https://www.buildingsandmore.com",
		"logo": "https://www.buildingsandmore.com/static/15071556b56eb7f9570713e6b266eebb/df682/logo.png",
		"contactPoint": { 
		"@type": "ContactPoint", 
		"telephone": "+1 (386) 755-6449", 
		"contactType": "customer service", 
		"areaServed": "US", 
		"availableLanguage": "en"
		}, 
		"sameAs": [
		  "https://www.facebook.com/BuildingsAndMore/", 
		  "https://www.instagram.com/buildingsandmorefl/", 
		  "https://www.youtube.com/channel/UC4gVU1Ei9nbWT6yNmYllSQQ", 
		  "https://www.pinterest.com/buildingsandmore/", 
		  "https://www.buildingsandmore.com/" 
		] 
	  }
	  const businessSchema = { 
		"@context": "https://schema.org", 
		"@type": "LocalBusiness", 
		"name": "Buildings And More", 
		"image": "https://www.buildingsandmore.com/static/15071556b56eb7f9570713e6b266eebb/8abb9/logo.webp", 
		"@id": "", 
		"url": "https://www.buildingsandmore.com/", 
		"telephone": "+1 (386) 755-6449", 
		"address": { 
		  "@type": "PostalAddress", 
		  "streetAddress": "792 SW Bascom Norris Drive", 
		  "addressLocality": "Lake City", 
		  "addressRegion": "FL", 
		  "postalCode": "32025", 
		  "addressCountry": "US" 
		}, 
		"geo": { 
		  "@type": "GeoCoordinates", 
		  "latitude": 30.1720092, 
		  "longitude": -82.6699471 
		}, 
		"openingHoursSpecification": [{ 
		  "@type": "OpeningHoursSpecification", 
		  "dayOfWeek": [ 
			"Monday", 
			"Tuesday", 
			"Wednesday", 
			"Thursday", 
			"Friday" 
		  ], 
		  "opens": "09:00", 
		  "closes": "17:00" 
		},{ 
		  "@type": "OpeningHoursSpecification", 
		  "dayOfWeek": "Saturday", 
		  "opens": "09:00", 
		  "closes": "14:00" 
		}],
		"sameAs": [
		  "https://www.facebook.com/BuildingsAndMore/", 
		  "https://www.instagram.com/buildingsandmorefl/", 
		  "https://www.youtube.com/channel/UC4gVU1Ei9nbWT6yNmYllSQQ", 
		  "https://www.pinterest.com/buildingsandmore/", 
		  "https://www.buildingsandmore.com/" 
		] 
	  }
	  const schema = [organizationSchema, businessSchema]
		return(
			<Layout>
				<Seo title="Contact Buildings And More for your building needs - Contact Us" description="Visit us at 792 SW Bascom Norris Dr. Lake City, FL 32025, or call (386) 755-6449 to have a chat." schemaMarkup={schema} />
				<BreadCrumb position="relative">
					<div className="container">
						<Link to="/">Home</Link><span>&nbsp;/&nbsp;</span>
						<span>Contact BAM</span>
					</div>
				</BreadCrumb>
				<Section pt="44px" pb="230px" bgColor="#F7F5F5">
					<div className="container">	
						<FlexWrap>
							<LeftWrap>
								<SectionPageTitle textAlign="left">Contact Us</SectionPageTitle>
								<SectionDescription textAlign="left">
									<p>Building And More wants to make sure that you get an accurate and affordable quote fast. Please contact us at <a href="tel:3867556449">(386) 755-6449</a> or use the form for pricing and additional information on the products you are interested in. Our buildings are available for viewing any time  during daylight hours.</p>
								</SectionDescription>
								<SubTitle fontSize="24px" textAlign="left">Lake City</SubTitle>
								<AddressWrap>
									<MapPointerIcon fill="#E43D3C" />
									<p>
									<a href="https://maps.app.goo.gl/Uy9Tda6jTpHv2kyF9" target="_blank" aria-label="maps">792 SW Bascom Norris Drive, Lake City, FL 32025</a>
									</p>
								</AddressWrap>
								<AddressWrap>
									<PhoneIcon fill="#E43D3C" />
									<p>
										<a href="tel:3867556449">(386) 755-6449</a>
									</p>
								</AddressWrap>
								<AddressWrap>
									<PhoneIcon fill="#E43D3C" />
									<p>
										<a href="tel:8552294729">(855) 229-4729 (Toll free)</a>
									</p>
								</AddressWrap>
								<SubTitle fontSize="24px" textAlign="left" mt="10px">Macclenny</SubTitle>
								<AddressWrap>
									<MapPointerIcon fill="#E43D3C" />
									<p>
									<a href="https://maps.app.goo.gl/o22iX8engdeS8rNy9" target="_blank" aria-label="maps">1168 Macclenny Avenue West, Macclenny, FL 32063</a>
									</p>
								</AddressWrap>
								<AddressWrap>
									<PhoneIcon fill="#E43D3C" />
									<p>
										<a href="tel:9042594054">(904) 259-4054</a>
									</p>
								</AddressWrap>
								<SubTitle fontSize="24px" textAlign="left" mt="10px">Starke</SubTitle>
								<AddressWrap>
									<MapPointerIcon fill="#E43D3C" />
									<p>
									
									<a href="https://maps.app.goo.gl/DqjPRWSU1aBzaJsR8" target="_blank" aria-label="maps">1540 South Walnut Street, Starke, FL 32091</a>
									</p>
								</AddressWrap>
								<AddressWrap>
									<PhoneIcon fill="#E43D3C" />
									<p>
										<a href="tel:9049660077">(904) 966-0077</a>
									</p>
								</AddressWrap>
								<AddressWrap>
									<MailIcon fill="#E43D3C" />
									<p>
										<a href="mailto:Info@buildingandmore.com">Info@buildingandmore.com</a>
									</p>
								</AddressWrap>
							</LeftWrap>
							<RightWrap>
								<FormWrap id="ContactForm" onSubmit={this.handleSubmit}>
									<h3>Get Your Custom Quote</h3>
									<FlexWrap id="contact form">
										<LeftWrap width="49%">
											<input type="text" name="first_name" id="first_name" onChange={this.handleChange} placeholder="First Name*" pattern="[A-Za-z\s]+$" required />
										</LeftWrap>
										<RightWrap width="49%">
											<input type="text" name="last_name" id="last_name" onChange={this.handleChange} placeholder="Last Name*" pattern="[A-Za-z\s]+$" required />
										</RightWrap>
									</FlexWrap>
									<FlexWrap>
										<LeftWrap width="49%">
											<input type="email" name="email" id="email" onChange={this.handleChange} placeholder="Email ID" required />
										</LeftWrap>
										<RightWrap width="49%">
											<input type="tel" name="phone" id="phone" onChange={this.handleChange} placeholder="Contact Number" pattern="[0-9]{10}" required />
										</RightWrap>
									</FlexWrap>
									<FlexWrap>
										<LeftWrap width="49%">
											<input type="text" name="city" id="city" onChange={this.handleChange} placeholder="City" pattern="[A-Za-z\s]+$" required />
										</LeftWrap>
										<RightWrap width="49%">
											<input type="number" name="zipcode" id="zipcode" onChange={this.handleChange} placeholder="Zip Code" pattern="[0-9]+$" required />
										</RightWrap>
									</FlexWrap>
									<FlexWrap>
										<textarea rows="3" id="comment" name="comment" onChange={this.handleChange} placeholder="Message" />
									</FlexWrap>
									<FlexWrap>
									<Checkbox>
									<label>
										<input
										className="form-control"
										type="checkbox"
										id="agreeViaSms"
										name="agreeViaSms"
										defaultChecked={true}
										onChange={this.handleChange}
										aria-label="contacted via SMS"
										/>
										{` `}I agree that I will be contacted via SMS.
									</label>
									</Checkbox>
									<HelpText>By clicking 'Submit,' you are agreeing to the Buildings And More Terms of Use and Privacy Policy. You are also consenting to receive phone calls and SMS messages from Buildings And More to provide updates and information regarding your business with Buildings And More. The frequency of messages may vary, and message and data rates may apply. If you wish to opt out of further messaging, reply STOP. For more information, reply HELP. Please refer to our Privacy Policy for details.</HelpText>
									</FlexWrap>

									<button type="submit">	
										<PrimaryLinkButton text="Submit" icon={<LineArrowRight />} />
									</button>
								</FormWrap>
							</RightWrap>
						</FlexWrap>
					</div>
				</Section>
				<MapWrap>
					<div className="container">	
						<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3449.2790913077574!2d-82.67215438488188!3d30.17202058183599!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88ef3a47ef23a335%3A0xd851a20b075653a1!2sBuildings%20And%20More%20-%20Lake%20City!5e0!3m2!1sen!2sin!4v1673594710679!5m2!1sen!2sin" width="100%" height="600" frameborder="0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="map"></iframe>
					</div>
				</MapWrap>
			</Layout>
		)
	}
}

export default Contact